import './list.scss';
import dataApi from '@/api/system/index.js';
import groupApi from '@/api/manage/memberGroup.js';
import rankApi from '@/api/manage/memberRank.js';
import memberApi from '@/api/manage/member.js';
import { validatenull } from '@/utils/validate.js';

const EDITORCONF = [
  'head',
  'italic',
  'underline',
  'strikeThrough',
  'lineHeight',
  'backColor',
  'link',
  'todo',
  'justify',
  'quote',
  'image',
  'video',
  'table',
  'code',
  'splitLine',
  'undo',
  'redo'
];
export default {
  name: 'setting',
  components: {},
  data () {
    return {
      activeName: 'system',
      member: [],
      memberGroup: [],
      memberRank: [],
      formDa: {},
      formOp: {
        column: [],
        group: [],
        labelPosition: 'right',
        labelSuffix: '：',
        labelWidth: 170,
        gutter: 0,
        menuBtn: true,
        submitBtn: true,
        submitText: '提交',
        emptyBtn: false,
        emptyText: '清空',
        menuPosition: 'center',
        detail: false
      },
      formSystem: [
        {
          type: 'input',
          label: '服务器地址',
          span: 24,
          display: true,
          prop: 'weburl',
          placeholder: '请输入服务器地址'
        },
        {
          type: 'input',
          label: '网站名称',
          span: 24,
          display: true,
          prop: 'webtitle'
        },
        {
          type: 'input',
          label: '网站关键词',
          span: 24,
          display: true,
          prop: 'keywords',
          tip: '多个关键词用“,”分隔开',
          tipPlacement: 'bottom'
        },
        {
          type: 'textarea',
          label: '网站介绍',
          span: 24,
          display: true,
          prop: 'description',
          maxlength: 200,
          showWordLimit: true
        },
        {
          type: 'input',
          label: '网站备案号',
          span: 12,
          display: true,
          prop: 'beian'
        },
        {
          type: 'input',
          label: '客服电话',
          span: 12,
          display: true,
          prop: 'telephone'
        },
        {
          type: 'input',
          label: '客服微信号',
          span: 12,
          display: true,
          prop: 'wechat_id'
        },
        {
          label: '客服二维码',
          span: 24,
          display: true,
          type: 'upload',
          prop: 'service_qr',
          propsHttp: {},
          dataType: 'string',
          listType: 'picture-img',
          oss: 'ali',
          multiple: false,
          limit: 1,
          accept: 'jpg/png',
          tip: '只能上传jpg/png文件，且不超过500kb',
          action: `${this.$baseUrl}system/upload`
        }
      ],
      formWechat: [
        {
          label: '公众号配置',
          prop: 'account',
          arrow: false,
          collapse: true,
          display: true,
          column: [
            {
              type: 'input',
              label: 'AppID',
              span: 24,
              display: true,
              prop: 'appid',
              placeholder: '开发者ID(AppID)'
            },
            {
              type: 'input',
              label: 'AppSecret',
              span: 24,
              display: true,
              prop: 'appsecret',
              placeholder: '开发者密码(AppSecret)'
            },
            {
              type: 'input',
              label: '令牌(Token)',
              span: 24,
              display: true,
              prop: 'token'
            },
            {
              type: 'input',
              label: 'EncodingAESKey',
              span: 24,
              display: true,
              prop: 'aeskey',
              placeholder: '消息加解密密钥(EncodingAESKey)'
            },
            {
              label: '公众号二维码',
              span: 24,
              display: true,
              type: 'upload',
              prop: 'wechat_qr',
              propsHttp: {},
              dataType: 'string',
              listType: 'picture-img',
              oss: 'ali',
              multiple: false,
              limit: 1,
              accept: 'jpg/png',
              tip: '只能上传jpg/png文件，且不超过500kb',
              action: `${this.$baseUrl}system/upload`
            }
          ]
        },
        {
          label: '模板消息',
          prop: 'tplgroup',
          arrow: false,
          collapse: true,
          display: true,
          column: [
            {
              title: '警告',
              component: 'el-alert',
              span: 24,
              params: {
                title: '公众号模板消息模板id从微信公众号后台获取',
                type: 'warning'
              }
            },
            {
              type: 'input',
              label: '注册成功',
              span: 24,
              display: true,
              prop: 'reg_member',
              placeholder: '推荐人会员注册成功'
            },
            {
              type: 'input',
              label: '会员等级改变',
              span: 24,
              display: true,
              prop: 'change_level',
              placeholder: '会员等级改变'
            },
            {
              type: 'input',
              label: '报单提交成功',
              span: 24,
              display: true,
              prop: 'registration',
              placeholder: '报单提交成功'
            },
            {
              type: 'input',
              label: '报单核准成功',
              span: 24,
              display: true,
              prop: 'declaration',
              placeholder: '报单核准通过'
            },
            {
              type: 'input',
              label: '奖金发放',
              span: 24,
              display: true,
              prop: 'bonus',
              tipPlacement: 'bottom',
              placeholder: '奖金发放通知'
            },
            {
              type: 'input',
              label: '提现审核',
              span: 24,
              display: true,
              prop: 'withdrawal',
              placeholder: '提现审核通过'
            },
            {
              type: 'input',
              label: '新产品通知',
              span: 24,
              display: true,
              prop: 'new_product',
              placeholder: '新产品通知'
            },
            {
              type: 'input',
              label: '产品变更',
              span: 24,
              display: true,
              prop: 'update_product',
              placeholder: '产品变更通知'
            },
            {
              type: 'input',
              label: '进件提醒(管理)',
              span: 24,
              display: true,
              prop: 'admin_into',
              placeholder: '新进件提醒'
            },
            {
              type: 'input',
              label: '报单提醒(管理)',
              span: 24,
              display: true,
              prop: 'admin_trading',
              placeholder: '新报单提醒'
            }
          ]
        }
      ],
      formOther: [
        {
          type: 'select',
          label: '默认会员等级',
          span: 24,
          display: true,
          props: {
            label: 'name',
            value: 'id'
          },
          prop: 'default_member_rank',
          dataType: 'number'
        },
        {
          type: 'select',
          label: '默认会员组',
          span: 24,
          display: true,
          props: {
            label: 'name',
            value: 'id'
          },
          prop: 'default_member_group',
          dataType: 'number'
        },
        {
          type: 'input',
          label: '最低提现金额',
          span: 24,
          display: true,
          prop: 'withdraw_min',
          placeholder: '最低提现金额',
          append: '元'
        },
        {
          type: 'input',
          label: '提现手续费',
          span: 24,
          display: true,
          prop: 'poundage',
          placeholder: '提现手续费百分比',
          append: '%'
        }, {
          type: 'input',
          label: '提现个税比',
          span: 24,
          display: true,
          prop: 'tax',
          placeholder: '提现个税百分比',
          append: '%'
        }, {
          type: 'select',
          label: '通知操作员',
          span: 24,
          display: true,
          prop: 'operator',
          formslot: true
        },
        {
          label: '提现说明',
          type: 'ueditor',
          component: 'avue-ueditor',
          span: 24,
          display: true,
          prop: 'withdrawal_tip',
          maxlength: 1500,
          showWordLimit: true,
          readonly: false,
          options: {
            customConfig: {
              showFullScreen: false, // 是否全屏
              pasteFilterStyle: false, // 关闭样式过滤
              excludeMenus: EDITORCONF
            }
          }
        }
      ]
    };
  },
  computed: {},
  created () {},
  mounted () {
    this.formOp.group = [];
    this.formOp.column = this.formSystem;
    this.getInfo('system');
    this.getMemberGroup();
    this.getMemberRank();
    this.getMember();
  },
  filters: {},
  methods: {
    // 返回
    goBack () {
      this.$router.go(-1);
    },
    // 选项卡切换
    handleClick (tab) {
      switch (tab.name) {
        case 'system':
          this.formOp.group = [];
          this.formOp.column = this.formSystem;
          break;
        case 'wechat':
          this.formOp.column = [];
          this.formOp.group = this.formWechat;
          break;
        case 'sms':
          this.formOp.group = [];
          this.formOp.column = this.formSms;
          break;
        case 'other':
          this.formOp.group = [];
          this.formOp.column = this.formOther;
          this.$refs.form.updateDic('default_member_group', this.memberGroup);
          this.$refs.form.updateDic('default_member_rank', this.memberRank);
          break;
        default:
          break;
      }
      this.formDa = { ...undefined };
      this.$refs.form.resetFields();
      this.getInfo(tab.name);
    },
    // 添加
    handleSubmit (form, done) {
      const field = JSON.stringify(form);
      const postData = { scenario: this.activeName, field: field };
      dataApi.confPost.r(postData).then(res => {
        done(form);
        this.formDa = res.data;
        this.getInfo(this.activeName);
      });
    },
    getInfo (value) {
      const postData = {
        scenario: value
      };
      const that = this;
      that.formDa = {};
      dataApi.confInfo.r(postData).then(res => {
        if (!validatenull(res.data)) {
          that.formDa = res.data;
          console.log('===================================');
          if (value === 'other') {
            this.getMember('', res.data.operator);
          }
        }
      });
    },
    memberChangeSelect () {

    },
    getMember (query, isEcho = '') {
      const postData = {
        nickname: query,
        is_echo: isEcho,
        page: 1,
        pageSize: 10
      };
      memberApi.getList.r(postData).then(res => {
        this.member = res.data.list;
      });
    },
    getMemberRank () {
      const postData = {
        page: 1,
        pageSize: 100
      };
      rankApi.getList.r(postData).then(res => {
        this.memberRank = res.data.list;
      });
    },
    getMemberGroup () {
      const postData = {
        page: 1,
        pageSize: 100
      };
      groupApi.getList.r(postData).then(res => {
        this.memberGroup = res.data.list;
      });
    }
  }
};
